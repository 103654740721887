import React, { useEffect } from 'react'
import { Box, Button, Container, Flex, Heading, HStack, Image, Img, Stack, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import turtleImage from '../images/Turtle.png';
import { Swiper, SwiperSlide } from "swiper/react";
// import SwiperCore, { Autoplay } from 'swiper';
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { heroBannerSlider } from './data/sliderData';
import { useState } from 'react';
import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    query, where,
    update
} from "firebase/firestore";
import { db } from '../firebase/FirebaseConfig';
import { IoIosJet, IoIosCreate } from "react-icons/io";

function HeroSection() {
    const bgColor = useColorModeValue("rgb(230, 253, 255)", "blackAlpha.200");
    const nftCollectionRef = collection(db, "collections");
    const [allCollection, setCollection] = useState();



    useEffect(() => {


        const getCollection = async () => {
            const data = await getDocs(nftCollectionRef);
            setCollection(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getCollection();

    }, []);
    //SwiperCore.use([Autoplay]);
    return (
        <>
            <Box w="100%" bgColor={bgColor} pt={{ base: "150px", md: "30px" }}>
                <Container maxW={'7xl'}>
                    <Swiper

                        // breakpoints={{
                        //     // when window width is >= 640px
                        //     640: {
                        //         width: 640,
                        //         slidesPerView: 1,
                        //     },
                        //     // when window width is >= 768px
                        //     768: {
                        //         width: 768,
                        //         slidesPerView: 1,
                        //     },

                        // }}

                        spaceBetween={50}
                        pagination={{
                            clickable: true,
                        }}
                        //navigation={true}


                        loop={true}
                        autoplay={
                            {
                                delay: 5000,
                                disableOnInteraction: false,
                            }
                        }
                        modules={[Pagination, Autoplay]}
                        freeMode={true}
                        speed={1000}
                        freeModeMomentum={false}
                        className="mySwiper"
                    >


                        {allCollection ? allCollection.map((item) => (

                            <SwiperSlide>
                                <Stack

                                    align={'center'}
                                    spacing={{ base: 8, md: 10 }}
                                    py={{ base: 0, md: 28 }}
                                    px="30px"
                                    // border="1px solid gray"
                                    // borderRadius="15px"
                                    direction={{ base: 'column', md: 'row' }}>
                                    <Stack flex={1} spacing={{ base: 5, md: 10 }}>
                                        <Heading
                                            lineHeight={1.1}
                                            fontWeight={600}
                                            fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}>
                                            <Text
                                                as={'span'}
                                                position={'relative'}
                                                color="alphaBlack.900"
                                                _after={{
                                                    content: "''",
                                                    width: 'full',
                                                    height: '30%',
                                                    position: 'absolute',
                                                    bottom: 1,
                                                    left: 0,
                                                    zIndex: -1,
                                                }}>
                                                {item.collectionName}
                                            </Text>
                                            <br />
                                            {/* <Text as={'span'} color="#76B947">
                                use everywhere!
                            </Text> */}
                                        </Heading>
                                        <Text color={'alphaBlack.600'}>
                                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Enim magnam maxime soluta nemo maiores non perspiciatis perferendis dicta voluptatem nostrum est corporis atque velit fuga, sequi ratione quibusdam. Similique, ipsam?
                                        </Text>
                                        <Stack
                                            spacing={{ base: 4, sm: 6 }}
                                            direction={{ base: 'row', sm: 'row' }}>
                                            <Link to='/collection'>
                                                <Button

                                                    size={'lg'}
                                                    fontWeight={'normal'}
                                                    px={6}
                                                    bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}
                                                    borderRadius="20px"
                                                    w="150px"
                                                    boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                                                    leftIcon={<IoIosJet />}
                                                >
                                                    Explore
                                                </Button>
                                            </Link>
                                            <Link to='/mint'>
                                                <Button

                                                    size={'lg'}
                                                    fontWeight={'normal'}
                                                    px={6}
                                                    bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}
                                                    borderRadius="20px"
                                                    w="150px"
                                                    boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                                                    leftIcon={<IoIosCreate />}
                                                >
                                                    Create
                                                </Button>
                                            </Link>
                                        </Stack>
                                    </Stack>
                                    <Flex
                                        flex={2}
                                        justify={'center'}
                                        align={'center'}
                                        position={'relative'}
                                        w={'full'}

                                    >

                                        <Box
                                            position={'relative'}
                                            rounded={'2xl'}
                                            boxShadow={'2xl'}
                                            width={'full'}
                                            overflow={'hidden'}>

                                            <Image
                                                alt={'Hero Image'}
                                                fit={'cover'}
                                                align={'center'}
                                                w={'100%'}
                                                h={'100%'}
                                                src={item.bannerImage}
                                            />
                                        </Box>
                                    </Flex>
                                </Stack>
                            </SwiperSlide>

                        )) : null}



                    </Swiper>
                </Container>
            </Box>

        </>

    )
}

export default HeroSection