import * as React from 'react';
import { Link, Button, Flex, Box, useDisclosure, Icon, VStack, useColorModeValue } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { CloseIcon } from '@chakra-ui/icons'

function NavMenu({ link }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    //console.log(link);
    const bg = useColorModeValue("whiteAlpha.900", "#6faa6b");
    return (
        <Box pos="relative">
            <Button
                as="a"
                cursor="pointer"
                colorScheme={'whiteAlpha'}
                color={bg}
                variant={'ghost'}
                onMouseEnter={onOpen}
                onMouseLeave={onClose}

            >
                {/* {link.label ? link.label : <Icon as={link.icon} w={'6'} h={'6'} />} */}
                {link.label == "Info" ?
                    <VStack>
                        {<Icon as={link.icon} w={'6'} h={'6'} />}

                        {/* <p style={{ fontSize: "12px" }}>{link.label}</p> */}
                    </VStack>

                    :
                    <VStack>
                        {<Icon as={link.icon} w={'6'} h={'6'} />}

                        <p style={{ fontSize: "12px" }}>{link.label}</p>
                    </VStack>
                }


            </Button>
            <NavHoverOptions
                onMouseEnter={onOpen}
                onMouseLeave={onClose}
                isOpen={isOpen}
                animate={isOpen ? 'open' : 'closed'}
                options={link.children}
                onClose={onClose}
            />
        </Box>
    );
}

function NavHoverOptions({ options, isOpen, onClose, ...props }) {
    const handleBox = () => {
        onClose();
    }
    return (
        <NavMenuPopup py="3" {...props}>
            <Flex
                as="ul"
                listStyleType={'none'}
                direction={'column'}
                color="gray.800"
            >
                {options ? options.map(option => {
                    //console.log(option);
                    return (
                        <Box
                            key={option.label}
                            as="li"
                            _hover={{
                                bg: 'gray.100',
                            }}
                            px="20px"
                            py="10px"
                            cursor="pointer"
                            onClick={handleBox}
                        >
                            {/*<ChakraLink
                                px="6"
                                py="2"
                                _hover={{
                                    textDecoration: 'none',
                                }}
                                d="block"
                                fontSize={'md'}
                                fontWeight="medium"
                            >
                                {' '}
                                {option.label}
                            </ChakraLink>*/}
                            {<Link as={ReactRouterLink} to={`${option.href}`} className="linkDesign"
                                   target={option.newTab ? "_blank" : ""}
                                   rel={option.newTab ? "noopener noreferrer" : ""}
                            > {option.label}</Link>}
                        </Box>
                    );
                }) : null}
            </Flex>
        </NavMenuPopup>
    );
}

export const MotionBox = motion(Box);

const NavMenuPopup = ({ onClose, ...props }) => (
    <MotionBox
        border="2px solid black"
        initial="init"
        variants={variants}
        outline="0"
        opacity="0"
        bg="white"
        pos="fixed"
        bottom="6"
        left="2px"
        right="2px"
        shadow="lg"
        rounded="2xl"
        mt="0"
        {...props}
    >
    </MotionBox>
);
const variants = {
    init: {
        opacity: 0,
        y: 'calc(-60.99px)',
        // y: 'calc(-100% - 56.99px)',
        display: 'none',
        transition: {
            duration: 0,
        },
    },
    open: {
        opacity: 1,
        y: 'calc(-50.99px)',
        display: 'block',
        transition: {
            duration: 0.2,
        },
    },
    closed: {
        opacity: 0,
        y: 'calc(-60.99px)',
        transition: {
            duration: 0.2,
        },
        transitionEnd: {
            display: 'none',
        },
    },
};

export default NavMenu;
