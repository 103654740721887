import {
    Heading,
    Avatar,
    Box,
    Center,
    Image,
    Flex,
    Text,
    Stack,
    Button,
    useColorModeValue,
    HStack,
    VStack,
    Img,
    Divider,
} from '@chakra-ui/react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../components/header/Header';
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useState } from 'react';
import algo from '../components/images/Icons/algo.svg';
import usdc from '../components/images/Icons/usdc.png';

function WithdrawLiquidity(props) {
    const location = useLocation();
    const [val, setVal] = useState();
    const bgColor = useColorModeValue("rgb(230, 253, 255)", "blackAlpha.200");
    console.log(location.state);

    const marks = {

        0: <strong>0%</strong>,
        25: '25%',
        50: '50%',
        75: '75%',
        100: {
            style: {
                color: 'green',
            },
            label: <strong>100%</strong>,
        },
    };

    function log(value) {
        setVal(value)
        // setBorrowAmount(borrowPrice * value / 100);
        // dispatch(NFTborrowAmountAction(borrowPrice * value / 100));
        console.log(value); //eslint-disable-line

    }
    return (
        <>
            <Header />
            <Box minH="100vh" py="50px" mt="100px" >
                <Center py={6}>
                    <VStack maxW={'450px'}
                        w={'full'}
                        gap="20px"
                    >
                        <Box
                            maxW={'450px'}
                            w={'full'}
                            bg={useColorModeValue('white', 'gray.800')}
                            boxShadow={'2xl'}
                            rounded={'md'}
                            overflow={'hidden'}
                            h="230px"
                            bgGradient='linear(to-r, #85BF47, #38B455)'
                        >
                            <HStack justify="left" justifyContent="space-between" px="20px" pt="20px">
                                <VStack align="left">
                                    <Text fontSize="18px" fontWeight="medium" color="whiteAlpha.900">ALGO</Text>
                                    <Text fontSize="25px" fontWeight="medium" color="whiteAlpha.900">0.0090</Text>
                                    <Text color="whiteAlpha.900">≈ $0</Text>
                                </VStack>
                                <VStack align="left">
                                    <Text fontSize="18px" fontWeight="medium" color="whiteAlpha.900">USDC</Text>
                                    <Text fontSize="25px" fontWeight="medium" color="whiteAlpha.900">0.0090</Text>
                                    <Text color="whiteAlpha.900">≈ $0</Text>
                                </VStack>

                            </HStack>

                            <HStack justify="left" justifyContent="space-between" px="20px" pt="20px">

                                <VStack align="left">
                                    <Text fontSize="14px" color="whiteAlpha.900">Share of Pool</Text>
                                    <Text fontSize="18px" color="whiteAlpha.900">0.000001%</Text>
                                </VStack>

                            </HStack>

                        </Box>

                        <Box
                            maxW={'450px'}
                            w={'full'}
                            bg={useColorModeValue('white', 'gray.800')}
                            boxShadow={'2xl'}
                            rounded={'md'}
                            overflow={'hidden'}
                            h="230px"

                        >
                            <VStack align="left" py="20px" px="20px">
                                <Text fontSize="16px" fontWeight="500">Remove Amount</Text>
                                <Text fontSize="45px" color="yellow.400" >{val}%</Text>
                            </VStack>
                            <HStack justifyContent="space-between" justifyItems="center" px="20px" py="30px" width="100%" bgColor={bgColor} m="10px 0px 40px 0px" borderRadius="15px" pb="20px">
                                <Slider min={0} marks={marks} step={1} max={100} onChange={log} defaultValue={0} />
                            </HStack>
                        </Box>

                        <Box
                            maxW={'450px'}
                            w={'full'}
                            bg={useColorModeValue('white', 'gray.800')}
                            boxShadow={'2xl'}
                            rounded={'md'}
                            overflow={'hidden'}
                            h="230px"

                        >
                            <VStack align="left" py="20px" px="20px" gap={5}>
                                <HStack justify="center" justifyContent="space-between">
                                    <HStack>
                                        <Img src={location.state ? location.state.icon0 : algo} w="25px" h="25px" />
                                        <Text fontSize="18px" fontWeight="700">{location.state ? location.state.label0 : "Algo"}</Text>
                                    </HStack>
                                    <VStack>
                                        <Text fontSize="18px" fontWeight="700">0.000001</Text>
                                        <Text fontSize="18px" fontWeight="700">≈ $0</Text>
                                    </VStack>
                                </HStack>
                                <Divider />

                                <HStack justify="center" justifyContent="space-between">
                                    <HStack>
                                        <Img src={location.state ? location.state.icon1 : usdc} w="25px" h="25px" />
                                        <Text fontSize="18px" fontWeight="700">{location.state ? location.state.label1 : "USDC"}</Text>
                                    </HStack>
                                    <VStack>
                                        <Text fontSize="18px" fontWeight="700">0.000001</Text>
                                        <Text fontSize="18px" fontWeight="700">≈ $0</Text>
                                    </VStack>
                                </HStack>
                            </VStack>

                        </Box>

                        <Button w="100%" py="30px" bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }} fontSize="18px">Remove Liquidity</Button>
                    </VStack>
                </Center>
            </Box>


        </>
    )
}

export default WithdrawLiquidity