import { Box, Button, Container, Divider, Flex, HStack, Icon, Image, Input, InputGroup, InputLeftElement, Select, SimpleGrid, Stack, Text, Tooltip, useColorModeValue, useDisclosure, VStack, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormControl, FormLabel, Alert, AlertIcon, Spinner } from '@chakra-ui/react'
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import DesktopNav from '../components/layout/Navbar/DesktopNav/DesktopNav';
import pp from '../components/images/Turtle-with-Scooter.png';
import coverPhoto from '../components/images/images.png';
import { AtSignIcon, BellIcon, EmailIcon, InfoIcon, LinkIcon, SearchIcon, ArrowUpDownIcon, HamburgerIcon } from '@chakra-ui/icons';
import { IoIosGrid, IoMdGrid, IoIosHeartEmpty } from "react-icons/io";
import { useState } from 'react';
import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc, query, where
} from "firebase/firestore";
import { db } from '../components/firebase/FirebaseConfig';
import { NFTStorage, File, Blob } from 'nft.storage';
import { useNavigate } from 'react-router-dom';
import Header from '../components/header/Header';
import { FacebookIcon, TwitterIcon } from "react-share";
import { FacebookShareButton, TwitterShareButton } from "react-share";


function Profile() {
    const algoAdd = useSelector(state => state.token.myAlgoAddress);
    const textColor = useColorModeValue("gray.600", "whiteAlpha.500");
    const nftCollectionRef = collection(db, "nfts");
    const [nfts, setNfts] = useState();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const modal2 = useDisclosure();
    const userRef = collection(db, "users");

    const [image, setImage] = useState();
    const [coverImage, setCoverImage] = useState();
    const [name, setName] = useState();
    const [description, setDescription] = useState();
    const imageMimeType = /image\/(png|jpg|jpeg)/i;
    const [user, setUser] = useState();
    const [updateMess, setMess] = useState(false);
    const navigate = useNavigate();
    const [allUsers, setAllUsers] = useState();
    const [condition, setCondition] = useState(false);
    useEffect(() => {
        // let totalDipositAmount = 0;
        // let totalBorrowAmount = 0;
        const getNfts = async () => {
            const data = await getDocs(nftCollectionRef);
            setNfts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getNfts();

        const getUser = async () => {

            const q2 = query(collection(db, "users"), where("address", "==", algoAdd));

            const data2 = await getDocs(q2);
            data2.forEach(async (doc) => {
                // doc.data() is never undefined for query doc snapshots
                let docData = doc.data();


                //   console.log(doc.id, " => ", doc.data());
                await setUser({ ...user, user: docData, userId: doc.id })




            });
        };

        getUser();

        const getAllUsers = async () => {
            const data = await getDocs(userRef);
            setAllUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getAllUsers();


    }, [updateMess]);
    const user_id = user ? user.userId : null
    const myNFT = nfts ? nfts.filter((data) => data.owner_address === algoAdd) : null;
    // =============== check for new user ====================
    const check_for__new_user = allUsers ? allUsers.find((data) => data.address === algoAdd) : null;
    // if (check_for__new_user.length === 0) {
    //     setCondition(!condition);
    // }
    // =============== check for new user ends ==============
    const onImageChange = (e) => {
        const file = e.target.files[0];
        if (!file.type.match(imageMimeType)) {
            alert("Image type is not valid");
            return;
        }
        setImage(file);
    };



    const onCoverImageChange = (e) => {
        const file = e.target.files[0];
        if (!file.type.match(imageMimeType)) {
            alert("Image type is not valid");
            return;
        }
        setCoverImage(file);
    };


    const handleSubmitForUpdateUser = async (e) => {
        e.preventDefault();
        modal2.onOpen();
        try {

            const NFT_STORAGE_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweGNGZDFlY2MxNDIxOWJERkNBQTRiRjdkRDVjODRCNmQyM2Y2MzlEYWIiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY2MDI0ODQzODgxOSwibmFtZSI6ImhpbWVsIn0.mMZhIVtiAO72iqDf8CfqfQ9JNyACUfuUzNXdo0yxkSg'
            const client = new NFTStorage({ token: NFT_STORAGE_TOKEN })
            const metadata = await client.store({
                name: 'My',
                description: 'J',
                image: image
            })
            let metaDataUrl = metadata.data.image.href.replace('ipfs://', '')
            const firebase_url = `https://nftstorage.link/ipfs/${metaDataUrl}`;
            const asset_url = metadata.data.image.href;
            //const firebase_url = `https://nftstorage.link/ipfs${metadata.data.image.pathname}`;
            //const asset_url = metadata.data.image.href.replace('bafybeifubbk3omidztux2h475733vq55in35prink3az4npn72fyurmhdy', '');
            //const asset_url = metadata.data.image.href;
            let firebase_url2 = undefined;
            let asset_url2 = undefined;

            const metadata2 = await client.store({
                name: 'My something',
                description: 'J',
                image: coverImage
            })
            let metaDataUrl2 = metadata2.data.image.href.replace('ipfs://', '')
            firebase_url2 = `https://nftstorage.link/ipfs/${metaDataUrl2}`;
            asset_url2 = metadata2.data.image.href;
            //firebase_url2 = `https://nftstorage.link/ipfs${metadata2.data.image.pathname}`;
            //asset_url2 = metadata.data.image.href.replace('bafybeifubbk3omidztux2h475733vq55in35prink3az4npn72fyurmhdy', '');;
            //asset_url2 = metadata2.data.image.href;

            //createNft(firebase_url, firebase_url2, asset_url, asset_url2);
            //await addDoc(userRef, { address: algoAdd, userName: editProfile.name, image: firebase_url, coverImage: firebase_url2 });
            modal2.onClose();
            const userDoc = doc(db, "users", user_id);
            let user_name = name ? name : "";
            let desc = description ? description : "";
            let profile_pic = firebase_url ? firebase_url : null;
            let cover_pic = firebase_url2 ? firebase_url2 : null;
            await updateDoc(userDoc, { address: algoAdd, userName: user_name, description: desc, image: profile_pic, coverImage: cover_pic });

            setMess(true)
            setTimeout(() => {
                setMess(false);

            }, 5000);

            setTimeout(() => {
                onClose();
                navigate("/profile");
            }, 5000);
        } catch (error) {

        }


    }

    const handleSubmitForAddUser = async (e) => {
        e.preventDefault();
        alert("I am add function");
        modal2.onOpen();
        try {



            const NFT_STORAGE_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweGNGZDFlY2MxNDIxOWJERkNBQTRiRjdkRDVjODRCNmQyM2Y2MzlEYWIiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY2MDI0ODQzODgxOSwibmFtZSI6ImhpbWVsIn0.mMZhIVtiAO72iqDf8CfqfQ9JNyACUfuUzNXdo0yxkSg'
            const client = new NFTStorage({ token: NFT_STORAGE_TOKEN })
            const metadata = await client.store({
                name: 'My',
                description: 'J',
                image: image
            })
            let metaDataUrl = metadata.data.image.href.replace('ipfs://', '')
            const firebase_url = `https://nftstorage.link/ipfs/${metaDataUrl}`;
            const asset_url = metadata.data.image.href;
            //const firebase_url = `https://nftstorage.link/ipfs${metadata.data.image.pathname}`;
            //const asset_url = metadata.data.image.href.replace('bafybeifubbk3omidztux2h475733vq55in35prink3az4npn72fyurmhdy', '');
            //const asset_url = metadata.data.image.href;
            let firebase_url2 = undefined;
            let asset_url2 = undefined;

            const metadata2 = await client.store({
                name: 'My something',
                description: 'J',
                image: coverImage
            })
            let metaDataUrl2 = metadata2.data.image.href.replace('ipfs://', '')
            firebase_url2 = `https://nftstorage.link/ipfs/${metaDataUrl2}`;
            asset_url2 = metadata2.data.image.href;
            //firebase_url2 = `https://nftstorage.link/ipfs${metadata2.data.image.pathname}`;
            //asset_url2 = metadata.data.image.href.replace('bafybeifubbk3omidztux2h475733vq55in35prink3az4npn72fyurmhdy', '');;
            //asset_url2 = metadata2.data.image.href;

            //createNft(firebase_url, firebase_url2, asset_url, asset_url2);
            //await addDoc(userRef, { address: algoAdd, userName: editProfile.name, image: firebase_url, coverImage: firebase_url2 });
            modal2.onClose();
            //const userDoc = doc(db, "users", user_id);
            let user_name = name ? name : "";
            let desc = description ? description : "";
            let profile_pic = firebase_url ? firebase_url : null;
            let cover_pic = firebase_url2 ? firebase_url2 : null;
            // await updateDoc(userDoc, { address: algoAdd, userName: user_name, description: desc, image: profile_pic, coverImage: cover_pic });
            await addDoc(userRef, { address: algoAdd, userName: user_name, description: desc, image: profile_pic, coverImage: cover_pic });

            setMess(true)
            setTimeout(() => {
                setMess(false);

            }, 5000);

            setTimeout(() => {
                onClose();
                navigate("/profile");
            }, 5000);
        } catch (error) {

        }


    }
    return (
        <>
            <Header />
            <Box minH="100vh" w="100%" py="30px" >
                {/* profile pic section */}
                <Box width="100%" >
                    <VStack alignItems="flex-start">
                        <Image src={user ? user.user.coverImage : coverPhoto} w="100%" h="300px" marginBottom="-110px" objectFit="cover" mt="50px" />
                        <Box paddingLeft={{ base: "40px", md: "60px" }} >
                            <Image src={user ? user.user.image : pp} h="200px" w="200px" border="10px solid gray" borderColor="whiteAlpha.900" bg="gray.400" borderRadius="50%" objectFit="cover" />
                        </Box>
                    </VStack>
                </Box>
                <Container maxW={'8xl'} px={{ base: "20px", md: "25px" }}>
                    <Stack alignItems="start" justifyContent="space-between" paddingBottom="15px" direction={{ base: 'column', md: 'row' }}>
                        <Text fontSize="25px" fontWeight="600">{user ? user.user.userName : "Galapago"}</Text>
                        <Flex gap={5} >
                            {check_for__new_user === undefined ?
                                <Button bg="#6FAA6B" color="whiteAlpha.900" onClick={onOpen}>Add Profile</Button>
                                :

                                <Button bg="#6FAA6B" color="whiteAlpha.900" onClick={onOpen}>Edit Profile</Button>
                            }
                            <FacebookShareButton
                                url={"https://docs.galapago.app"}
                                // hashtag={"#hashtag"}
                                // description={"aiueo"}
                                className="Demo__some-network__share-button"
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>

                            <TwitterShareButton
                                title={"test"}
                                url={"https://docs.galapago.app"}
                            >
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>
                            {/* <Tooltip hasArrow label='Search places' bg='gray.300' color='black'>
                                <SearchIcon h="25px" w="25px" color="gray.500" />
                            </Tooltip>
                            <Tooltip hasArrow label='Tag' bg='gray.300' color='black'>
                                <AtSignIcon h="25px" w="25px" color="gray.500" />
                            </Tooltip>
                            <Tooltip hasArrow label='Notification' bg='gray.300' color='black'>
                                <BellIcon h="25px" w="25px" color="gray.500" />
                            </Tooltip>

                            <Divider orientation='vertical' h="20px" size="30px" />
                            <Tooltip hasArrow label='Link' bg='gray.300' color='black'>
                                <LinkIcon h="25px" w="25px" color="gray.500" />
                            </Tooltip>
                            <Tooltip hasArrow label='Info' bg='gray.300' color='black'>
                                <InfoIcon h="25px" w="25px" color="gray.500" />
                            </Tooltip> */}
                        </Flex>
                    </Stack>
                    <VStack w={{ base: "100%", md: "70%" }} align="left" paddingBottom="30px">
                        <HStack>
                            <Text fontSize="14px" fontWeight="600">{algoAdd.substring(0, 8) + "..." + algoAdd.substr(algoAdd.length - 8)}</Text>
                            <Text fontWeight="600">Joined June 2022</Text>
                        </HStack>


                        <Text fontSize="18px" color={textColor}>{user ? user.user.description : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita molestias explicabo aperiam adipisci saepe repellat. Nostrum illum sequi incidunt possimus officia ullam obcaecati rem illo, voluptas quae! Eum vero porro neque nulla maiores fuga nemo voluptates quae saepe iste! Beatae expedita ullam neque odio minus ut nemo veniam, velit aperiam."}</Text>
                    </VStack>

                    <HStack p="40px 0px 20px 0px" gap={10}>
                        <Box>
                            <Text fontSize="21px" paddingBottom="" fontWeight="400" _hover={{ borderBottom: "1px solid black", fontWeight: "700" }}>Items</Text>
                        </Box>
                        <Box>
                            <Text fontSize="21px" paddingBottom="" fontWeight="400" _hover={{ borderBottom: "1px solid black", fontWeight: "700" }}>Activity</Text>
                        </Box>
                    </HStack>
                    <HStack py="20px">
                        <Box p="8px" _hover={{ shadow: 'lg' }} w="5%" textAlign="center">
                            <HamburgerIcon w="25px" h="25px" />
                        </Box>
                        <Box w="70%" >
                            <InputGroup>
                                <InputLeftElement children={<Icon name="Search2Icon" color="gray.300" />} />
                                <Input type="search" placeholder="Search by name or attribute" />
                            </InputGroup>
                        </Box>
                        <Box w="20%" _hover={{ shadow: 'xl' }}>
                            <Select>
                                <option value='option1'>Recently Listed</option>
                                <option value='option2'>Recently Created</option>
                                <option value='option3'>Recently Sold</option>
                            </Select>
                        </Box>
                        <Box w="5%" border="1px solid gray" p="6px" borderRadius="8px" borderColor="gray.200">
                            <HStack >
                                <IoIosGrid size="25px" cursor="pointer" />
                                <Divider orientation='vertical' h="20px" />
                                <IoMdGrid size="25px" cursor="pointer" />
                            </HStack>
                        </Box>
                    </HStack>
                    <Stack direction={{ base: 'column', md: "row" }}>

                        <Box w="100%" minH="200px">
                            <SimpleGrid columns={3} spacing={5}>
                                {myNFT ? myNFT.map((item) => (
                                    <Box h="450px" border="1px solid gray" borderColor="gray.300" borderRadius="12px" boxShadow="md" cursor="pointer" key={item.nft_id}>
                                        <Image src={item.url} h="300px" w="100%" borderTopRightRadius="12px" borderTopLeftRadius="12px" objectFit="cover" />
                                        <HStack alignItems="center" justifyContent="space-between" p="12px 12px 0px 12px" marginBottom="-10px">
                                            <Text fontSize="14px" color="gray.500">{item.name}</Text>
                                            <Text fontSize="14px" color="gray.500">Price</Text>
                                        </HStack>
                                        <HStack alignItems="center" justifyContent="space-between" p="12px 12px 0px 12px" marginBottom="20px">
                                            <Text fontSize="14px" >{item.nft_id}</Text>
                                            {/* <HStack>
                                                <Image src={algo} w="25px" h="25px" />
                                                <Text fontSize="14px" >{item.price}</Text>
                                            </HStack> */}

                                        </HStack>
                                        {/* <HStack justifyContent="flex-end" p="12px">
                                            <Text fontSize="11px" >Last</Text>
                                            <Image src={algo} w="18px" h="18px" />
                                            <Text fontSize="11px" >{item.old_price}</Text>
                                        </HStack> */}
                                        <Divider color="gray.300" />
                                        <HStack justifyContent="flex-end" p="12px">

                                            <IoIosHeartEmpty />
                                        </HStack>
                                    </Box>
                                )) : null}


                            </SimpleGrid>
                        </Box>
                    </Stack>
                    <HStack paddingTop="40px" gap={10} h="400px">

                    </HStack>
                </Container>
            </Box>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{check_for__new_user === undefined ? "Add user" : "Update user"}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>

                        <form onSubmit={check_for__new_user === undefined ? handleSubmitForAddUser : handleSubmitForUpdateUser}>
                            <Stack direction="column" gap={2}>
                                <FormControl>
                                    <FormLabel >Picture</FormLabel>
                                    {/* <FormHelperText>Upload image for your asset</FormHelperText> */}
                                    <Input
                                        type="file"
                                        name='image'
                                        //value={image}
                                        onChange={(e) => onImageChange(e)}
                                    />
                                </FormControl>

                                <FormControl>
                                    <FormLabel >Cover Photo</FormLabel>
                                    {/* <FormHelperText>Upload image for your asset</FormHelperText> */}
                                    <Input
                                        type="file"
                                        name='coverImage'
                                        //value={coverImage}
                                        onChange={(e) => onCoverImageChange(e)}
                                    />
                                </FormControl>

                                <FormControl>
                                    <FormLabel >Your Name</FormLabel>
                                    <Input
                                        type="text"
                                        name='name'
                                        value={name ?? ""}
                                        onChange={(e) => setName(e.target.value)}

                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel >Description</FormLabel>
                                    <Input
                                        type="text"
                                        name='description'
                                        value={description ?? ""}
                                        onChange={(e) => setDescription(e.target.value)}

                                    />
                                </FormControl>

                                <Button bg="green.500" color="whiteAlpha.900" type="submit" > Submit</Button>
                                {/* <Button onClick={()=>initialize_escrow(AppId,assetId)}>Initilize app</Button> */}
                            </Stack>

                        </form>

                        {
                            updateMess ?
                                <Alert status='success'>
                                    <AlertIcon />
                                    Your profile has been successful. Fire on!
                                </Alert>
                                :
                                null
                        }
                    </ModalBody>

                </ModalContent>
            </Modal>

            <Modal isOpen={modal2.isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Uploading profile info.....</ModalHeader>
                    <ModalBody alignItems="center" py="20px">
                        <HStack alignItems="center" justifyContent="space-evenly">
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                h="120px"
                                w="120px"
                            />
                        </HStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default Profile