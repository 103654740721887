import { Box, Button, Container, Flex, Heading, HStack, Icon, IconButton, Image, Select, SimpleGrid, Stack, Text, useBreakpointValue, useColorModeValue, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import CaptionCarousel from '../components/marketPalaceComponent/CaptionCarousel';
import TopCollectionComponent from '../components/marketPalaceComponent/TopCollectionComponent';
import TrendingInAllComponent from '../components/marketPalaceComponent/TrendingInAllComponent';
import { createAndSellNFT } from '../components/marketPalaceComponent/data/sliderData';
import { resourceForGettingStarted } from '../components/marketPalaceComponent/data/sliderData';
import bgImage from '../components/images/componentImages/bgDemo.png';
import { useSelector } from 'react-redux';
import DesktopNav from '../components/layout/Navbar/DesktopNav/DesktopNav';
import turtleImage from '../components/images/Turtle.png';
import image1 from '../components/images/Concept-4.png'
import { Link } from 'react-router-dom';
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc, query, where
} from "firebase/firestore";
import { db } from '../components/firebase/FirebaseConfig';
import { useDispatch } from 'react-redux';
import { AllNFTAction, BorowAprAction, DepositRateAction } from '../components/redux/actions';
import { useNavigate } from 'react-router-dom';
import HeroSection from '../components/marketPalaceComponent/HeroSection';
import Header from '../components/header/Header';


function MarketPalace() {
  const navigate = useNavigate();
  const bgColor = useColorModeValue("rgb(230, 253, 255)", "blackAlpha.200");
  const nftDepositRef = collection(db, "deposit");
  const nftBorrowRef = collection(db, "borrow");
  const [nftDetails, setNftDetails] = useState();
  const [total_diposit_amount, setDiposit] = useState();
  const [total_borrow_amount, setBorrow] = useState();
  const dispatch = useDispatch();

  const nftCollectionRef = collection(db, "nfts");
  const [nfts, setNfts] = useState([]);
  dispatch(AllNFTAction(nfts));
  let totalDipositAmount = 0;
  let totalBorrowAmount = 0;
  useEffect(() => {

    const getNfts = async () => {
      const data = await getDocs(nftCollectionRef);
      setNfts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    getNfts();
    //=============================================================
    const depositAmount = async () => {

      const data = await getDocs(nftDepositRef);
      //console.log(data)
      data.docs.map((doc) => {
        totalDipositAmount += parseInt(doc.data().amount)
        //console.log(totalDipositAmount);
        // dispatch(NFTtotalDepositAmount(totalDipositAmount));
        setDiposit(totalDipositAmount);
      })
      // setDepositState(data.docs.map((doc) => ({ ...doc.data(), algoAdd: doc.owner_address })));
    };


    const borrowAmount = async () => {

      const data = await getDocs(nftBorrowRef);
      // console.log(data);
      data.docs.map((doc) => {
        totalBorrowAmount += parseInt(doc.data().amount)
        // console.log("total borrow amount" + totalBorrowAmount)
        setBorrow(totalBorrowAmount);
        // dispatch(NFTtotalBorrowAmount(totalBorrowAmount));
      })
      // setDepositState(data.docs.map((doc) => ({ ...doc.data(), algoAdd: doc.owner_address })));
    };



    depositAmount();
    borrowAmount();

    const getNftRange = async () => {
      let rate = totalBorrowAmount / totalDipositAmount;
      let checkRate = Math.floor(rate);
      let checkRate2 = checkRate + 5;
      const q2 = query(collection(db, "range"), where("utilization_rate", ">", checkRate), where("utilization_rate", "<", checkRate2));

      const data2 = await getDocs(q2);
      data2.forEach(async (doc) => {
        // doc.data() is never undefined for query doc snapshots
        let docData = doc.data();
        //   console.log(doc.id, " => ", doc.data());
        await setNftDetails({ ...nftDetails, nftDetails: docData, nftId: doc.id })




      });

      //setBorrowRate(data2.docs.map((doc) => ({ ...doc.data(), id: doc.borrow_rate })));


      // };


    }
    setTimeout(() => {
      getNftRange();
    }, 5000);

    //===============================================================
  }, []);
  const borrow_rate = nftDetails ? nftDetails.nftDetails.borrow_rate : 0;
  const deposit_rate = nftDetails ? nftDetails.nftDetails.deposit_rate : 0;
  const borrow_apr = ((((total_borrow_amount + (total_borrow_amount * (borrow_rate / 100)) + ((total_borrow_amount * (borrow_rate / 100) * 0.3))) - total_borrow_amount) / total_borrow_amount) * 100).toFixed(2);
  dispatch(BorowAprAction(borrow_apr));
  dispatch(DepositRateAction(deposit_rate));

  //console.log(nftDetails.nftDetails);
  return (
    <>

      <Header />
      <Box minH="100vh" >
        {/* ==================heroSection SLider=================== */}
        <HeroSection />
        {/* ==================heroSection SLider=================== */}
        {/* 2nd component starts from here  */}
        <Container maxW={'7xl'} py={{ base: "50px", md: "100px" }} >
          <HStack borderRadius="12px" h={{ base: "100px", md: "200px" }} p={{ base: "20px", md: "40px" }} border="1px solid gray" borderColor="blackAlpha.400" justifyContent="space-between" bgImage={image1} backgroundRepeat="no-repeat" backgroundPosition="center">
            <Box>
              <Text fontSize={{ base: "14px", md: "25px" }} color="whiteAlpha.900" fontWeight="bold">Lorem ipsum dolor sit amet.</Text>
            </Box>
            <Box>
              <Link to='/collection'>
                <Button _hover={{ bg: '#76B947' }} bgColor="#76B947" width="150px" borderRadius="20px">Explore</Button>
              </Link>
            </Box>
          </HStack>
        </Container>
        {/* 2nd component end here  */}

        <Box w="100%" bgColor={bgColor}>
          {/* 3rd component starts from here  */}
          <Container maxW={'7xl'} paddingTop="70px">
            <Text textAlign="center" fontSize={{ base: "18px", md: "32px" }} mb="30px" fontWeight="bold">Notable Drops</Text>
            <CaptionCarousel />
          </Container>
          {/* 3rd component end here  */}
          {/* 4th component starts from here  */}
          <Container maxW={'7xl'} mt={{ base: "5px", md: "100px" }}>
            <HStack alignItems="center" justifyContent="center" py={{ base: "40px", md: "40px" }}>
              <Text textAlign="center" fontSize={{ base: "14px", md: "25px" }} fontWeight="bold" >Top collections over</Text>
              <Select w="200px" border="none" fontSize={{ base: "14px", md: "25px" }} color="blue.800" fontWeight="bold" _focus={{ border: "none" }}>
                <option value='option1' >Last 24 hours</option>
                <option value='option2'>Last 7 days</option>
                <option value='option3'>Last 30 days</option>
              </Select>
            </HStack>
            <TopCollectionComponent />
          </Container>
          {/* 4th component end here  */}
          {/* 5th component starts from here  */}
          <Container maxW={'7xl'} mt={{ base: "5px", md: "60px" }} >
            <Text textAlign="center" fontSize={{ base: "18px", md: "32px" }} mb="50px" fontWeight="bold">Trending in
              all categories
            </Text>
            <TrendingInAllComponent />
          </Container>
          {/* 5th component end here  */}
        </Box>

        {/* 6th component starts from here  */}
        <Box w="100%" bgColor={bgColor} py="50px" textAlign="center">
          <Container maxW={'7xl'}  >
            <Text textAlign="center" fontSize={{ base: "18px", md: "32px" }} mb="50px" fontWeight="bold">Create and sell your NFTs
            </Text>
            <Stack direction={{ base: 'column', md: 'row' }} gap={5} >
              {createAndSellNFT.map((item) => (
                <VStack gap={3} align="center" justifyContent="center" w={{ base: "100%", md: "300px" }} p="20px" key={item.id} >
                  <Image src={item.icon} h="50px" w="50px" />
                  <Heading fontSize="21px">{item.name}</Heading>
                  <Text textAlign="center" fontSize="16px">{item.desc}</Text>
                </VStack>
              ))}

            </Stack>
          </Container>
        </Box>
        {/* 6th component end here  */}
        {/* 7th component starts from here  */}
        <Box w="100%" bgColor={bgColor}>
          <Container maxW={'7xl'} py="60px">
            <Text textAlign="center" fontSize={{ base: "18px", md: "32px" }} mb="50px" fontWeight="bold">Resources for getting started </Text>
            <Stack direction={{ base: 'column', md: 'row' }} gap={5} mb="60px" borderRadius="12px" background={bgColor}>

              {resourceForGettingStarted.map((item) => (
                <VStack gap={3} align="center" justifyContent="center" w={{ base: "100%", md: "400px" }} key={item.id} boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" borderRadius="12px" _hover={{ boxShadow: "xl" }}>
                  <Image src={item.image} width="100%" h="350px" borderTopLeftRadius="12px" borderTopRightRadius="12px" />
                  <Text fontSize="18px" fontWeight="600" paddingBottom="40px">{item.title}</Text>
                </VStack>
              ))}


            </Stack>
          </Container>
        </Box>

        {/* 7th component end here  */}
        {/* 8th component starts from here  */}
        <Box w="100%">
          <Image src={bgImage} w="100%" h={{ base: "250px", md: "100%" }} objectFit="cover" />
        </Box>
        {/* 8th component ends here  */}

      </Box>
    </>

  )
}

export default MarketPalace;