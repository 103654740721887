import { Box, Button, Container, Divider, FormControl, FormHelperText, FormLabel, HStack, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, SimpleGrid, Stack, Text, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { IoIosHeartEmpty } from 'react-icons/io'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import algo from '../components/images/Icons/algo.svg';
import { db } from '../components/firebase/FirebaseConfig';
import {
    collection,
    getDocs,
    updateDoc,
    doc
} from "firebase/firestore";
import Header from '../components/header/Header';

function CollectionNFT() {

    const [nftData, setNftData] = useState([]);
    const algoAdd = useSelector(state => state.token.myAlgoAddress);
    const [indivualDataNft, setIndividualDataNft] = useState(nftData);
    const navigate = useNavigate();
    const nftCollectionRef = collection(db, "nfts");

    const handleSell = (item) => {
        if (item.address === algoAdd) {
            navigate("/single", { state: { person: true, data: item } });
        } else {
            navigate("/single", { state: { data: item } })
        }
    }

    useEffect(() => {
        /*
        *calling firebase for collecting NFTS
        */
        const getNfts = async () => {
            const data = await getDocs(nftCollectionRef);
            setNftData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        getNfts();


    }, [indivualDataNft]);


    /*
    *Filtering data according to collection
    */
    const handleFilterData = (e) => {
        let targetValue = e.target.value;
        if (e.target.value === "all") {
            const getNfts = async () => {
                const data = await getDocs(nftCollectionRef);
                setIndividualDataNft(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
            };
            getNfts();
        } else {
            const indivudual_nft_data = nftData.filter((data) => data.option === targetValue);
            setIndividualDataNft(indivudual_nft_data);
        }

    }

    /*
    **Owner can edit their NFT
    **It will pop up a form with relative fields
    */
    //==============Modal content =======================
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [editItem, setEditItem] = useState({
        description: "",
        name: "",
        price: "",
        option: "",
    });
    const {
        description,
        name,
        price,
        option
    } = editItem;
    const handleEditNFT = (item) => {
        setEditItem(item);

        console.log(editItem);
        onOpen();
    }
    const obj = {
        description: editItem.description,
        name: editItem.name,
        price: editItem.price,
        option: editItem.option,
    }
    //==============Modal content end here===============


    /*
    *when edit form is submitted, it will update the perticular NFT
    */
    const handleNFTUpdate = async (e) => {
        e.preventDefault();
        //console.log(name, priceNft, description, option);
        const firebaseId = editItem.id;
        const userDoc = doc(db, "nfts", firebaseId);
        await updateDoc(userDoc, { name: obj.name, description: obj.description, price: obj.price, option: obj.option });
        const getNfts = async () => {
            const data = await getDocs(nftCollectionRef);
            setIndividualDataNft(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        getNfts();
        onClose();
    }
    return (
        <>
            <Header />
            <Box w="100%" minH="100vh" py="50px">

                <Container maxW={'7xl'} mt="50px">
                    <Text fontSize="40px" fontWeight="bold" py={5} textAlign="center">Explore Collection</Text>

                    <HStack alignItems="center" gap={{ base: 1, md: 2 }} justifyContent="center">

                        <Button bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }} variant='ghost' fontSize={{ base: "14px", md: "19px" }} value="all" onClick={(e) => handleFilterData(e)}>
                            All
                        </Button>
                        <Button bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }} variant='ghost' fontSize={{ base: "14px", md: "19px" }} value="art" onClick={(e) => handleFilterData(e)}>
                            Art
                        </Button>
                        <Button bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }} variant='ghost' fontSize={{ base: "14px", md: "19px" }} value="game" onClick={(e) => handleFilterData(e)}>
                            Game
                        </Button>
                        <Button bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }} variant='ghost' fontSize={{ base: "14px", md: "19px" }} value="cartoon" onClick={(e) => handleFilterData(e)}>
                            Cartoon
                        </Button>
                        <Button bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }} variant='ghost' fontSize={{ base: "14px", md: "19px" }} value="animal" onClick={(e) => handleFilterData(e)}>
                            Animal
                        </Button>
                        <Button bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }} variant='ghost' fontSize={{ base: "14px", md: "19px" }} value="metaverse" onClick={(e) => handleFilterData(e)}>
                            Metaverse
                        </Button>
                    </HStack>
                </Container>

                <Container maxW={'7xl'} mt="50px">
                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>
                        {indivualDataNft.length === 0 ?
                            nftData.map((item) => (
                                <>
                                    <Box h="480px" border="1px solid gray" borderColor="gray.300" borderRadius="12px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" key={item.id}>
                                        <Box onClick={() => handleSell(item)} cursor="pointer">
                                            <Image src={item.url} h="300px" w="100%" borderTopRightRadius="12px" borderTopLeftRadius="12px" objectFit="cover" />
                                            <HStack alignItems="center" justifyContent="space-between" p="12px 12px 0px 12px" marginBottom="-10px">
                                                <Text fontSize="14px" color="gray.500">{item.name}</Text>
                                                <Text fontSize="14px" color="gray.500">Price</Text>
                                            </HStack>
                                            <HStack alignItems="center" justifyContent="space-between" p="12px 12px 0px 12px" marginBottom="-10px">
                                                <Text fontSize="14px" >{item.id}</Text>
                                                <HStack>
                                                    <Image src={algo} w="25px" h="25px" />
                                                    <Text fontSize="14px" >{item.price}</Text>
                                                </HStack>

                                            </HStack>
                                            <HStack justifyContent="flex-end" p="12px">
                                                <Text fontSize="11px" >Last</Text>
                                                <Image src={algo} w="18px" h="18px" />
                                                <Text fontSize="11px" >{item.old_price}</Text>
                                            </HStack>
                                            <Divider color="gray.300" />
                                        </Box>


                                        <Box h="100px">
                                            <HStack justifyContent="space-between" p="12px">
                                                {item.address === algoAdd ?
                                                    <Button onClick={() => handleEditNFT(item)} bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}>Edit</Button> : null
                                                }
                                                <IoIosHeartEmpty />
                                            </HStack>
                                        </Box>
                                    </Box>

                                </>

                            ))
                            :
                            indivualDataNft.map((item) => (
                                <>
                                    <Box h="480px" border="1px solid gray" borderColor="gray.300" borderRadius="12px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" key={item.id}>
                                        <Box onClick={() => handleSell(item)} cursor="pointer">
                                            <Image src={item.url} h="300px" w="100%" borderTopRightRadius="12px" borderTopLeftRadius="12px" objectFit="cover" />
                                            <HStack alignItems="center" justifyContent="space-between" p="12px 12px 0px 12px" marginBottom="-10px">
                                                <Text fontSize="14px" color="gray.500">{item.name}</Text>
                                                <Text fontSize="14px" color="gray.500">Price</Text>
                                            </HStack>
                                            <HStack alignItems="center" justifyContent="space-between" p="12px 12px 0px 12px" marginBottom="-10px">
                                                <Text fontSize="14px" >{item.id}</Text>
                                                <HStack>
                                                    <Image src={algo} w="25px" h="25px" />
                                                    <Text fontSize="14px" >{item.price}</Text>
                                                </HStack>

                                            </HStack>
                                            <HStack justifyContent="flex-end" p="12px">
                                                <Text fontSize="11px" >Last</Text>
                                                <Image src={algo} w="18px" h="18px" />
                                                <Text fontSize="11px" >{item.old_price}</Text>
                                            </HStack>
                                            <Divider color="gray.300" />
                                        </Box>


                                        <Box h="100px">
                                            <HStack justifyContent="space-between" p="12px">
                                                {item.address === algoAdd ?
                                                    <Button onClick={() => handleEditNFT(item)} >Edit</Button> : null
                                                }
                                                <IoIosHeartEmpty />
                                            </HStack>
                                        </Box>
                                    </Box>

                                </>

                            ))
                        }

                        <Modal isOpen={isOpen} onClose={onClose} isCentered>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>Edit your NFT</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <form >
                                        <Stack direction="column" gap={2}>
                                            <FormControl>
                                                <FormLabel >NFT Name</FormLabel>
                                                <Input
                                                    type="text"
                                                    name='username'
                                                    Value={editItem.name}
                                                    onChange={(e) => setEditItem({ ...editItem, name: e.target.value })}
                                                />

                                            </FormControl>
                                            <FormControl>
                                                <FormLabel >Price</FormLabel>
                                                <Input
                                                    type="text"
                                                    name='price'
                                                    Value={editItem.price}
                                                    onChange={(e) => setEditItem({ ...editItem, price: e.target.value })}
                                                />

                                            </FormControl>
                                            <FormControl>
                                                <FormLabel >Description</FormLabel>
                                                <Input
                                                    type="text"
                                                    name='description'
                                                    Value={editItem.description}
                                                    onChange={(e) => setEditItem({ ...editItem, name: e.target.value })}
                                                />
                                            </FormControl>

                                            <FormControl>
                                                <FormLabel >Collection</FormLabel>
                                                <FormHelperText>This is the collection where your item will appear.</FormHelperText>
                                                <Select placeholder='Select option' value={editItem.option}
                                                    onChange={(e) => setEditItem({ ...editItem, option: e.target.value })}
                                                >
                                                    <option value='art'>Art</option>
                                                    <option value='cartoon'>Cartoon</option>
                                                    <option value='game'>Games</option>
                                                    <option value='animal'>Animal</option>
                                                </Select>
                                            </FormControl>
                                            <Button bg="green.500" color="whiteAlpha.900" type="submit" onClick={handleNFTUpdate}> Submit</Button>
                                        </Stack>
                                    </form>
                                </ModalBody>
                            </ModalContent>
                        </Modal>
                    </SimpleGrid>

                </Container>
            </Box>

        </>
    )
}

export default CollectionNFT