import {
    Box, Button, Container, FormControl, FormHelperText, FormLabel, Img, Input, InputGroup, InputLeftElement, Select, SimpleGrid, Stack, Text, Avatar,
    useColorModeValue, Heading, Image
} from '@chakra-ui/react';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import DesktopNav from '../components/layout/Navbar/DesktopNav/DesktopNav';
import { IoIosGlobe } from "react-icons/io";
import dummy from '../components/images/pago-head.png';
import { PhoneIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    query, where,
    update
} from "firebase/firestore";
import { useState } from 'react';
import { db } from '../components/firebase/FirebaseConfig';

function MyCollection() {
    const algoAdd = useSelector(state => state.token.myAlgoAddress);
    const algoBalance = useSelector(state => state.token.myAlgoBalance);
    const walletAddress = useSelector(state => state.token.walletConnectAddress);
    const walletAmount = useSelector(state => state.token.walletConnectBalance);
    const walletConnectStatus = useSelector(state => state.token.walletConnectStatus);
    const navigate = useNavigate();
    const [collectionData, setCollection] = useState([]);
    const nftCollectionRef = collection(db, "collections");
    const color = useColorModeValue('white', 'gray.900')
    const handleBtn = () => {
        navigate("/mycollection/create");
    }

    const handleCollection = (data) => {

        navigate("/nft-profile", { state: { item: data } });
    }


    useEffect(() => {

        // const getNfts = async () => {

        //     const q = query(collection(db, "nfts"));
        //     const data = await getDocs(q);

        //     const q2 = query(collection(db, "collections"), where("address", "==", algoAdd));

        //     const data2 = await getDocs(q2);
        //     console.log("inside nft details")

        //     data2.forEach(async (doc) => {
        //         // doc.data() is never undefined for query doc snapshots
        //         let docData = doc.data();


        //         //   console.log(doc.id, " => ", doc.data());
        //         await setCollection({ ...collectionData, collectionData: docData })




        //     });

        //     //setNfts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));


        // };

        // getNfts();

        const collection = async () => {
            const data = await getDocs(nftCollectionRef);
            setCollection(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        collection();




    }, []);
    //console.log(collectionData);

    const myCollection = collectionData.length > 0 ? collectionData.filter((data) => data.address === algoAdd) : null;
    //console.log(myCollection);

    return (
        <>
            <Box zIndex="100" top="0" position="fixed" width="100%">{walletConnectStatus ? <DesktopNav name={walletAddress} amount={walletAmount} /> : <DesktopNav name={algoAdd} amount={algoBalance} />}</Box>

            <Box minH="100vh" pt="50px">
                {/* first component start from here  */}

                <Box w="100%" >
                    <Container maxW={'5xl'} my="80px">
                        <Text fontSize="40px" fontWeight="bold">My Collections</Text>
                        <Text fontSize="18px" py="10px">Create, curate, and manage collections of unique NFTs to share and sell.</Text>

                        <Button bg="#6FAA6B" color="whiteAlpha.900" _hover={{ color: "#FFF" }} py="25px" onClick={handleBtn}>create a collection</Button>

                        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5} py="30px">
                            {myCollection ? myCollection.map((data, index) => (
                                <Box
                                    maxW={'445px'}
                                    w={'full'}
                                    bg={color}
                                    boxShadow={'2xl'}
                                    rounded={'md'}
                                    p={6}
                                    overflow={'hidden'}
                                    cursor="pointer"
                                    key={index}
                                    onClick={() => handleCollection(data)}
                                >
                                    <Box
                                        maxH={'200px'}
                                        bg={'gray.100'}
                                        mt={-6}
                                        mx={-6}
                                        mb={6}
                                        pos={'relative'}>
                                        <Image
                                            src={data.featureImage}
                                            layout={'fill'}
                                            w="100%"
                                            h="150px"
                                            objectFit="cover"
                                        />
                                    </Box>
                                    <Stack>

                                        <Text color={'gray.500'}>
                                            {data.description}
                                        </Text>
                                    </Stack>
                                    <Stack mt={6} direction={'row'} spacing={4} align={'center'}>
                                        <Avatar
                                            src={data.logoImage}
                                            alt={'Author'}
                                        />
                                        <Stack direction={'column'} spacing={0} fontSize={'sm'}>
                                            <Text fontWeight={600}>{data.collectionName}</Text>

                                        </Stack>
                                    </Stack>
                                </Box>
                            )) :
                                <Box
                                    maxW={'445px'}
                                    w={'full'}
                                    bg={color}
                                    boxShadow={'2xl'}
                                    rounded={'md'}
                                    p={6}
                                    overflow={'hidden'}>
                                    <Box
                                        h={'210px'}
                                        bg={'gray.100'}
                                        mt={-6}
                                        mx={-6}
                                        mb={6}
                                        pos={'relative'}>
                                        <Image
                                            src={
                                                'https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
                                            }
                                            layout={'fill'}
                                        />
                                    </Box>
                                    <Stack>

                                        <Text color={'gray.500'}>
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                                            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
                                            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
                                            et ea rebum.
                                        </Text>
                                    </Stack>
                                    <Stack mt={6} direction={'row'} spacing={4} align={'center'}>
                                        <Avatar
                                            src={'https://avatars0.githubusercontent.com/u/1164541?v=4'}
                                            alt={'Author'}
                                        />
                                        <Stack direction={'column'} spacing={0} fontSize={'sm'}>
                                            <Text fontWeight={600}>Achim Rolle</Text>

                                        </Stack>
                                    </Stack>
                                </Box>
                            }

                        </SimpleGrid>


                    </Container>
                </Box>
            </Box>
        </>
    )
}

export default MyCollection