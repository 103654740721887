import React, { useEffect, useState } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Icon,
  Box,
  Button,
  Flex,
  HStack,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Divider,
  Image,
  useColorModeValue,
  Img,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure
} from '@chakra-ui/react';
import { Md3DRotation, MdAllOut } from 'react-icons/md';
import NavMenu from './NavMenuDesktop';
import { links, languages, profile } from '../_data';
import SettingsModal from './ModalDesktop';
import gala from '../../../images/galaWhite.png';
import coinImage from '../../../images/Pago-Coin.png';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query, where,
  update
} from "firebase/firestore";
import { db } from '../../../firebase/FirebaseConfig';
import * as consoe from "react-dom/test-utils";
import { peraWallet } from "../../../../pages/WalletNew";
import { walletDisconnect } from '../../../redux/actions'

function Navbar(props) {
  const algoAdd = useSelector(state => state.token.myAlgoAddress);
  const peraAdd = useSelector(state => state.token.perawallet_address);
  const bgColor = useColorModeValue("BlackAlpha.900", "whiteAlpha.900");
  // const algoBalance = useSelector(state => state.token.myAlgoBalance);
  // const walletAddress = useSelector(state => state.token.walletConnectAddress);
  // const walletAmount = useSelector(state => state.token.walletConnectBalance);
  // const walletConnectStatus = useSelector(state => state.token.walletConnectStatus);
  const serverName = useSelector(state => state.token.serverName);
  const address = props.name;
  const res = address.substring(0, 8);
  const [nameServer, setNameServer] = useState(serverName);
  const bg = useColorModeValue("#6faa6b", "whiteAlpha.900");
  const color = useColorModeValue("whiteAlpha.900", "#6faa6b",);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [user, setUser] = useState()
  const dispatch = useDispatch();

  function handleDisconnectWalletClick() {
    peraWallet.disconnect();
    dispatch(walletDisconnect());
  }

  useEffect(() => {

    const getUser = async () => {

      try {
        const q = query(collection(db, "nfts"));
        const data = await getDocs(q);

        const q2 = query(collection(db, "users"), where("address", "==", algoAdd));

        const data2 = await getDocs(q2);
        //console.log("inside nft details")

        data2.forEach(async (doc) => {
          // doc.data() is never undefined for query doc snapshots
          let docData = doc.data();
          //   //console.log(doc.id, " => ", doc.data());
          await setUser({ ...user, user: docData, nftId: doc.id })
        });
      } catch (err) {
        console.error("Error fetching user data: ", err);
      }
    };

    getUser();

  }, []);
  //console.log(user);

  return (
    <Flex
      color={'white'}
      pos="sticky"
      zIndex={'100'}
      px="4"
      py="4"
      bgColor={bg}
      d="flex"

      justifyContent={'space-between'}
    >
      <HStack alignItems={'center'} gap="6">
        <Flex alignItems={'center'} gap="2">
          <Link to='/swap'>
            <Box >
              <Image src={gala} alt='Galapago' h="40px" w="100px" />
            </Box>
          </Link>

        </Flex>
        <Flex
          display={{
            base: 'none',
            md: 'flex',

            //py: '10px'
          }}

        >
          {links.map(link => {
            return <NavMenu key={link.label} link={link} />;
          })}
        </Flex>
      </HStack>

      <HStack>
        <Button
          cursor="pointer"
          colorScheme={'whiteAlpha'}
          //color="#eee"
          color={color}
          variant={'ghost'}
          _focus={{
            boxShadow: 'none',
          }}
        >
          {nameServer}
        </Button>
        {profile.map(link => {
          return <NavMenu key={String(Date.now())} link={link} />;
        })}
        {languages.map(link => {
          return <NavMenu key={String(Date.now())} link={link} />;
        })}




        {/* ==================testing=============== */}

        {/* <Menu isOpen={isOpen} color="red">
          <MenuButton onMouseEnter={onOpen} onMouseLeave={onClose} >
            <Img src={user ? user.user.image : coinImage} w="35px" h="35px" borderRadius="50%" objectFit="cover" />
          </MenuButton>

          <MenuList onMouseEnter={onOpen} onMouseLeave={onClose} mt="11px">
            <Link to="/profile">
              <MenuItem color="blackAlpha.900">Profile</MenuItem>
            </Link>

            <Link to="/mycollection">
              <MenuItem color="blackAlpha.900">My Collections</MenuItem>
            </Link>
          </MenuList>
        </Menu> */}

        {/* ==================testing=============== */}
        {/* <LanguageModal /> */}
        <SettingsModal />

        <Popover>
          <PopoverTrigger>
            <Button
              display={{
                base: 'none',
                sm: 'flex',
              }}
              size={'sm'}
              width="220px"
              cursor="pointer"
              bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}
              variant={'solid'}
              rounded="full"
              textTransform={'uppercase'}
              gap="10px"
            >
              {res} <Divider orientation='vertical' h="20px" /> <Image src={coinImage} />{props.amount}
            </Button>
          </PopoverTrigger>
          <PopoverContent width="140px">
            <PopoverArrow />
            <PopoverBody>
              <Flex justifyContent="center">
                <Button
                  display={{
                    base: 'none',
                    sm: 'flex',
                  }}
                  size={'sm'}
                  width="100px"
                  cursor="pointer"
                  bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}
                  variant={'solid'}
                  rounded="full"
                  gap="10px"
                  onClick={() => {
                    handleDisconnectWalletClick()
                  }}
                >
                  Disconnect
                </Button>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Popover>


        {/*
        <Link to="">
          <Button
            display={{
              base: 'none',
              sm: 'flex',
            }}
            size={'sm'}
            width="220px"
            cursor="pointer"
            bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}
            variant={'solid'}
            rounded="full"
            textTransform={'uppercase'}
            gap="10px"
          >
            {res} <Divider orientation='vertical' h="20px" /> <Image src={coinImage} />{props.amount}
          </Button>
        </Link> */}

      </HStack>
    </Flex>
  );
}

export default Navbar;
