import React from 'react';
import { Flex, Img, useColorModeValue } from '@chakra-ui/react';
import NavMenuMobile from './NavMenuMobile';
import { links } from '../_data';
import { MdMoreHoriz, MdLanguage } from 'react-icons/md';
function Navbar() {
  const bg = useColorModeValue("#6faa6b", "whiteAlpha.900");


  return (
    <Flex
      display={{
        base: 'flex',
        md: 'none',
      }}
      color={'white'}
      pos="fixed"
      bottom={0}
      w="full"
      zIndex={'2'}
      px="0"
      py="4"
      bgColor={bg}
      d="flex"
      justifyContent={'space-between'}


    >
      <Flex
        alignItems={'center'}
        justifyContent="space-between"
        flex="1"
        gap="0"
      >
        {links.map(link => {
          //console.log(link);
          //console.log(link);
          //console.log(link);
          return <NavMenuMobile key={link.label} link={link} />;
        })}
      </Flex>
    </Flex>
  );
}

export default Navbar;
