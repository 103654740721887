import { Box } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import DesktopNav from '../../components/layout/Navbar/DesktopNav/DesktopNav';


function Header() {
    const algoAdd = useSelector(state => state.token.myAlgoAddress);
    const algoBalance = useSelector(state => state.token.myAlgoBalance);
    const walletAddress = useSelector(state => state.token.walletConnectAddress);
    const walletAmount = useSelector(state => state.token.walletConnectBalance);
    const walletConnectStatus = useSelector(state => state.token.walletConnectStatus);
    const peraWalletAddress = useSelector(state => state.token.perawallet_address);



    return (
        <Box zIndex="100" top="0" position="fixed" width="100%">{walletConnectStatus ? <DesktopNav name={walletAddress} amount={walletAmount} /> : <DesktopNav name={algoAdd} amount={algoBalance} />}</Box>
    )
}

export default Header