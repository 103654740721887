import { Alert, AlertIcon, Box, Button, Container, FormControl, FormHelperText, FormLabel, HStack, Img, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Select, Spinner, Stack, Text, useDisclosure } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import Header from '../components/header/Header';
import { IoIosGlobe } from "react-icons/io";
import { useState } from 'react';
import { NFTStorage } from 'nft.storage';
import { useSelector } from 'react-redux';
import {
    collection,
    addDoc,
} from "firebase/firestore";
import { db } from '../components/firebase/FirebaseConfig';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

function CreateCollection() {
    const [file, setFile] = useState();
    const algoAdd = useSelector(state => state.token.myAlgoAddress);
    const [featureFile, setFeatureFile] = useState();
    const [bannerFile, setBannerFile] = useState();
    const [fileDataURL, setFileDataURL] = useState(null);
    const [featureDataURL, setFeatureDataURL] = useState(null);
    const [bannerDataURL, setBannerDataURL] = useState(null);
    const [name, setName] = useState();
    const [url, setURL] = useState();
    const [description, setDescription] = useState();
    const [category, setCategory] = useState();
    const [link, setLink] = useState();
    const [blockchain, setBlockchain] = useState();
    const imageMimeType = /image\/(png|jpg|jpeg|gif)/i;
    const modal2 = useDisclosure();
    const navigate = useNavigate();
    const nftCollectionRef = collection(db, "collections");
    const [mess, setMess] = useState();
    /*
    ** storing images in local state
    */
    const onLogoImageChange = (e) => {
        const file = e.target.files[0];
        if (file.name.length > 24) {
            alert("File name is too long. Please choose a file with a name less than 24 characters.");
            return;
        }
        if (!file.type.match(imageMimeType)) {
            alert("Image type is not valid");
            return;
        }
        setFile(file);
        console.log("Image link: " + file);
    };

    const onFeatureImageChange = (e) => {
        const file = e.target.files[0];
        if (file.name.length > 24) {
            alert("File name is too long. Please choose a file with a name less than 24 characters.");
            return;
        }
        if (!file.type.match(imageMimeType)) {
            alert("Image type is not valid");
            return;
        }
        setFeatureFile(file);
        console.log("Image link: " + file);
    }
    const onBannerImageChange = (e) => {
        const file = e.target.files[0];
        if (file.name.length > 24) {
            alert("File name is too long. Please choose a file with a name less than 24 characters.");
            return;
        }
        if (!file.type.match(imageMimeType)) {
            alert("Image type is not valid");
            return;
        }
        setBannerFile(file);
        console.log("Image link: " + file);
    }

    useEffect(() => {
        let fileReader, isCancel = false;
        if (file) {
            fileReader = new FileReader();
            fileReader.onload = (e) => {
                const { result } = e.target;
                if (result && !isCancel) {
                    setFileDataURL(result)
                }
            }
            fileReader.readAsDataURL(file);
        }

        if (featureFile) {
            fileReader = new FileReader();
            fileReader.onload = (e) => {
                const { result } = e.target;
                if (result && !isCancel) {
                    setFeatureDataURL(result)
                }
            }
            fileReader.readAsDataURL(featureFile);
        }

        if (bannerFile) {
            fileReader = new FileReader();
            fileReader.onload = (e) => {
                const { result } = e.target;
                if (result && !isCancel) {
                    setBannerDataURL(result)
                }
            }
            fileReader.readAsDataURL(bannerFile);
        }
        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        }
    }, [file, featureFile, bannerFile]);

    const handleRedirect = () => {
        navigate("/mycollection");
    }

    /*
    ** when form is submitted it will store all the images into ipfs, and image link also be stored in firebase.
    */
    const handleSubmit = async (e) => {
        e.preventDefault();
        modal2.onOpen();
        try {
            const NFT_STORAGE_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweGNGZDFlY2MxNDIxOWJERkNBQTRiRjdkRDVjODRCNmQyM2Y2MzlEYWIiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY2MDI0ODQzODgxOSwibmFtZSI6ImhpbWVsIn0.mMZhIVtiAO72iqDf8CfqfQ9JNyACUfuUzNXdo0yxkSg'
            const client = new NFTStorage({ token: NFT_STORAGE_TOKEN })
            const metadata = await client.store({
                name: 'My',
                description: 'J',
                image: file
            })
            let metaDataUrl = metadata.data.image.href.replace('ipfs://', '')
            const firebase_url = `https://nftstorage.link/ipfs/${metaDataUrl}`;
            const asset_url = metadata.data.image.href;
            let firebase_url2 = undefined;
            let asset_url2 = undefined;

            const metadata2 = await client.store({
                name: 'My some',
                description: 'J',
                image: featureFile
            })
            let metaDataUrl2 = metadata2.data.image.href.replace('ipfs://', '')
            firebase_url2 = `https://nftstorage.link/ipfs/${metaDataUrl2}`;
            asset_url2 = metadata2.data.image.href;


            let firebase_url3 = undefined;
            let asset_url3 = undefined;
            const metadata3 = await client.store({
                name: 'My something',
                description: 'J',
                image: bannerFile
            })
            let metaDataUrl3 = metadata3.data.image.href.replace('ipfs://', '')
            firebase_url3 = `https://nftstorage.link/ipfs/${metaDataUrl3}`;
            asset_url3 = metadata3.data.image.href;


            modal2.onClose();
            await addDoc(nftCollectionRef, { address: algoAdd, logoImage: firebase_url, featureImage: firebase_url2, bannerImage: firebase_url3, collectionName: name, collection_url: url, description: description, category: category, link: link, blockchain: blockchain });

            setMess(true)
            setTimeout(() => {
                setMess(false);

            }, 5000);

            setTimeout(() => {
                navigate("/mycollection")
            }, 5000);
        } catch (error) {
            console.log(error.message);
        }

    }
    return (
        <>
            <Header />
            <Box w="100%" minH="100vh">
                <Box px="30px" bgColor="gray" mt="70px">
                    <HStack py="30px">
                        <Text fontSize="15px" color="blackAlpha.900" fontWeight="700" cursor="pointer" onClick={handleRedirect}>My Collection </Text>
                        <ChevronRightIcon />
                        <Text>Create a collection</Text>
                    </HStack>
                </Box>
                <Container maxW={'3xl'} my="120px">
                    <Text fontSize="40px" fontWeight="bold">Create a Collection</Text>
                    <Text fontSize="15px" pb="20px"><span style={{ color: "red" }}>*</span> Required fields</Text>
                    <form onSubmit={handleSubmit}>
                        <Stack direction="column" gap={2}>
                            <FormControl>
                                <FormLabel >Logo Image <span style={{ color: "red" }}>*</span></FormLabel>
                                <Input
                                    type="file"
                                    name='photo'
                                    //value={img}
                                    border="none"
                                    p={1}
                                    mt={2}
                                    required={true}
                                    onChange={(e) => onLogoImageChange(e)}
                                />
                                {fileDataURL ?
                                    <p className="img-preview-wrapper">
                                        {
                                            <Img src={fileDataURL} alt="preview" height="120px" width="120px" borderRadius="50%" objectFit="cover" />
                                        }
                                    </p> : null}
                            </FormControl>

                            <FormControl>
                                <FormLabel >Feature Image <span style={{ color: "red" }}>*</span></FormLabel>
                                <FormLabel >This image will be used for featuring your collection on the homepage, category pages, or other promotional areas of OpenSea. 600 x 400 recommended.</FormLabel>
                                <Input
                                    type="file"
                                    name='photo'
                                    //value={img}
                                    border="none"
                                    p={1}
                                    mt={2}
                                    required={true}
                                    onChange={(e) => onFeatureImageChange(e)}
                                />
                                {featureDataURL ?
                                    <p className="img-preview-wrapper">
                                        {
                                            <Img src={featureDataURL} alt="preview" height="400px" width="600px" objectFit="cover" />
                                        }
                                    </p> : null}
                            </FormControl>

                            <FormControl>
                                <FormLabel >Banner image <span style={{ color: "red" }}>*</span></FormLabel>
                                <FormLabel >This image will appear at the top of your collection page. Avoid including too much text in this banner image, as the dimensions change on different devices. 1400 x 350 recommended.</FormLabel>
                                <Input
                                    type="file"
                                    name='photo'
                                    //value={img}
                                    border="none"
                                    p={1}
                                    mt={2}
                                    required={true}
                                    onChange={(e) => onBannerImageChange(e)}
                                />

                                {bannerDataURL ?
                                    <p className="img-preview-wrapper">
                                        {
                                            <Img src={bannerDataURL} alt="preview" height="350px" width="1400px" objectFit="cover" />
                                        }
                                    </p> : null}
                            </FormControl>
                            <FormControl>
                                <FormLabel > Name <span style={{ color: "red" }}>*</span></FormLabel>
                                <Input
                                    type="text"
                                    name='username'
                                    value={name ?? ""}
                                    required={true}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel >URL <span style={{ color: "red" }}>*</span></FormLabel>
                                <Input
                                    type="link"
                                    name='price'
                                    value={url ?? ""}
                                    required={true}
                                    onChange={(e) => setURL(e.target.value)}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel >Description <span style={{ color: "red" }}>*</span></FormLabel>
                                <FormHelperText>The description will be included on the item's detail page underneath its image. Markdown syntax is supported.</FormHelperText>
                                <Input
                                    type="text"
                                    name='description'
                                    value={description ?? ""}
                                    required={true}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel >Category <span style={{ color: "red" }}>*</span></FormLabel>
                                <FormHelperText>This is the collection where your item will appear.</FormHelperText>
                                <Select placeholder='Select option' value={category ?? ""}
                                    onChange={(e) => setCategory(e.target.value)}
                                >
                                    <option value='art'>Art</option>
                                    <option value='cartoon'>Cartoon</option>
                                    <option value='game'>Games</option>
                                    <option value='animal'>Animal</option>
                                    <option value='metaverse'>Metaverse</option>
                                </Select>
                            </FormControl>

                            <FormControl>
                                <FormLabel >Links <span style={{ color: "red" }}>*</span></FormLabel>

                                <Stack spacing={4}>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents='none'

                                            children={<IoIosGlobe color='gray.300' />}

                                        />
                                        <Input type='text' placeholder='yoursite.com' value={link ?? ""}
                                            onChange={(e) => setLink(e.target.value)} />
                                    </InputGroup>
                                </Stack>
                            </FormControl>

                            <FormControl>
                                <FormLabel >Blockchain <span style={{ color: "red" }}>*</span></FormLabel>
                                <Select
                                    value={blockchain}
                                    onChange={(e) => setBlockchain(e.target.value)}
                                    required={true}
                                >
                                    <option value='ethereum'>Ethereum</option>
                                    <option value='algorand'>Algorand</option>
                                </Select>
                            </FormControl>

                            <Button bg="green.500" color="whiteAlpha.900" type="submit" > Submit</Button>
                            {/* <Button onClick={()=>initialize_escrow(AppId,assetId)}>Initilize app</Button> */}
                        </Stack>
                        {
                            mess ?
                                <Alert status='success'>
                                    <AlertIcon />
                                    Your Collection has been created successfully. Fire on!
                                </Alert>
                                :
                                null
                        }


                    </form>
                </Container>

            </Box>

            {/* uploading info modal */}
            <Modal isOpen={modal2.isOpen} onClose={modal2.onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Uploading collection info.....</ModalHeader>
                    <ModalBody alignItems="center" py="20px">
                        <HStack alignItems="center" justifyContent="space-evenly">
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                h="120px"
                                w="120px"
                            />
                        </HStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {/* uploading info modal ends */}
        </>
    )
}

export default CreateCollection
