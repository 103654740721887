export const rates = [
    {
        id: 0,
        utilization: 1,
        borrow: 3.12,
        deposit: 0.02
    },
    {
        id: 1,
        utilization: 2,
        borrow: 3.12,
        deposit: 0.02
    },
    {
        id: 2,
        utilization: 3,
        borrow: 3.12,
        deposit: 0.02
    },
    {
        id: 3,
        utilization: 4,
        borrow: 3.12,
        deposit: 0.02
    },
    {
        id: 4,
        utilization: 5,
        borrow: 3.62,
        deposit: 0.13
    },
    {
        id: 5,
        utilization: 6,
        borrow: 3.62,
        deposit: 0.13
    },
    {
        id: 6,
        utilization: 7,
        borrow: 3.62,
        deposit: 0.13
    },
    {
        id: 7,
        utilization: 8,
        borrow: 3.62,
        deposit: 0.13
    },
    {
        id: 8,
        utilization: 9,
        borrow: 3.62,
        deposit: 0.13
    },
    {
        id: 9,
        utilization: 10,
        borrow: 4.23,
        deposit: 0.3
    },
]