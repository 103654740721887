import { Avatar, AvatarBadge, Box, Button, Divider, HStack, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react';
import avater from '../images/Draft.png';
import { topCollectionData } from './data/sliderData';
import { db } from '../firebase/FirebaseConfig';
import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    query, where,
    update
} from "firebase/firestore";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


function TopCollectionComponent() {
    const [collectionData, setCollection] = useState([]);
    const nftCollectionRef = collection(db, "collections");
    const algoAdd = useSelector(state => state.token.myAlgoAddress);
    const navigate = useNavigate();
    const handleItem = (data) => {

        navigate("/nft-profile", { state: { item: data } });
    }

    useEffect(() => {

        const collection = async () => {
            const data = await getDocs(nftCollectionRef);
            setCollection(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        collection();




    }, []);
    let id = 1;

    const myCollection = collectionData.length > 0 ? collectionData.filter((data) => data.address === algoAdd) : null;
    return (
        <>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>

                {collectionData ? collectionData.map((data, index) => (

                    <Box height='90px' borderBottom="1px" borderBottomColor="gray.300" alignItems="left" display="flex" justifyContent="space-between" key={index} cursor="pointer" onClick={() => handleItem(data)}>

                        <HStack gap={2}>
                            <Text>{index + 1}</Text>
                            <Avatar src={data.logoImage}>
                                <AvatarBadge boxSize='1.25em' bg='green.500' />
                            </Avatar>
                            <VStack align="left">
                                <Text fontWeight="bold" mb="-6px">{data.collectionName}</Text>
                                <Text color="alphaWhite.900">Floor price: 12</Text>
                            </VStack>
                        </HStack>
                        <VStack alignItems="center" justifyContent="center">
                            <Text fontWeight="bold" mb="-6px" color="green.600">12%</Text>
                            <Text color="alphaWhite.900">122</Text>

                        </VStack>
                    </Box>

                )) : null}


            </SimpleGrid>
            <HStack alignItems="center" justifyContent="center" py="50px">
                <Button textAlign="center" py="25px" w="250px" bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}> Go to Rankings</Button>
            </HStack>
        </>

    )
}

export default TopCollectionComponent