import { Box, Button, Container, HStack, Image, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tooltip, Tr, } from '@chakra-ui/react';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import DesktopNav from '../components/layout/Navbar/DesktopNav/DesktopNav';
import algo from '../components/images/Icons/algo.svg';
import usdc from '../components/images/Icons/usdc.png';
import pago from '../components/images/Icons/Pago-Coin.png';
import { IoIosCheckmarkCircleOutline, IoIosHelpCircleOutline, IoIosAddCircleOutline, IoIosSync, IoIosRemoveCircleOutline, IoIosSwap } from "react-icons/io";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../url';
import { useState } from 'react';
import Header from '../components/header/Header';


function Pools() {
    const algoAdd = useSelector(state => state.token.myAlgoAddress);
    const navigate = useNavigate();
    const [info, setInfo] = useState();
    const [info2, setInfo2] = useState();
    const [exchange, setExchange] = useState(false);
    const [exchange2, setExchange2] = useState(false);
    const [AlgoToUsdc, setAlgo] = useState();
    const [UsdcTOAlgo, setUsdc] = useState();
    const [AlgoTOPago, setAlgoToPago] = useState();
    const [PagoTOAlgo, setPagoToAlgo] = useState();

    const handleExchangeRate = () => {
        setExchange(!exchange);
    }

    const handleExchangeRate2 = () => {
        setExchange2(!exchange2);
    }

    const handleLiquidityRow1 = () => {
        navigate("/liquidity", { state: { icon0: algo, label0: "ALGO", id0: "0", icon1: usdc, label1: "USDC", id1: "10458941", exchangeRate1: AlgoToUsdc, exchangeRate2: AlgoToUsdc, pool_address: 62401500 } });

    }
    const handleLiquidityRow2 = () => {
        navigate("/liquidity", { state: { icon0: algo, label0: "ALGO", id0: "0", icon1: pago, label1: "PAGO", id1: "108232239", exchangeRate1: AlgoTOPago, exchangeRate2: PagoTOAlgo, pool_address: 101472972 } });
    }

    const handleWithdrawRow1 = () => {
        navigate("/withdraw", { state: { icon0: algo, label0: "ALGO", id0: "0", icon1: usdc, label1: "USDC", id1: "10458941", exchangeRate1: AlgoToUsdc, exchangeRate2: AlgoToUsdc, pool_address: 62401500 } });
    }

    const handleWithdrawRow2 = () => {
        navigate("/withdraw", { state: { icon0: algo, label0: "ALGO", id0: "0", icon1: pago, label1: "PAGO", id1: "108232239", exchangeRate1: AlgoTOPago, exchangeRate2: PagoTOAlgo, pool_address: 101472972 } });
    }


    useEffect(() => {
        const poolInfoRow1 = () => {
            const token1 = 0;
            const token2 = 10458941;
            const amount = 5;
            axios.get(`${API_URL}swap/poolInfo?address=` + algoAdd + '&asset1=' + token2 + '&asset2=' + token1 + '&asset2_amount=' + amount)
                .then(function (response) {
                    console.log(response);
                    setInfo(response.data.pool_info);

                    // const val2 = response.data.Asset2_price_perAsset1.toFixed(2);
                    // setinputTwo(val2);
                    // setLiquidityAsset(response.data.pool_info.liquidity_asset_id);
                    // console.log("liquidity1" + liquidityAsset1);
                    // setInputTwoInfo(response.data.Asset2_price_perAsset1_worst_case);
                })
        }

        poolInfoRow1();

        const poolInfoRow2 = () => {
            const token1 = 0;
            const token2 = 108232239;
            const amount = 5;
            axios.get(`${API_URL}swap/poolInfo?address=` + algoAdd + '&asset1=' + token2 + '&asset2=' + token1 + '&asset2_amount=' + amount)
                .then(function (response) {
                    console.log(response);
                    setInfo2(response.data.pool_info);

                    // const val2 = response.data.Asset2_price_perAsset1.toFixed(2);
                    // setinputTwo(val2);
                    // setLiquidityAsset(response.data.pool_info.liquidity_asset_id);
                    // console.log("liquidity1" + liquidityAsset1);
                    // setInputTwoInfo(response.data.Asset2_price_perAsset1_worst_case);
                })
        }

        poolInfoRow2();

        const exchange_for_algo_to_usdc = () => {
            const token2 = 10458941;
            const token1 = 0;
            const amount = 1;
            axios.get(`${API_URL}swap/poolInfo?address=` + algoAdd + '&asset1=' + token2 + '&asset2=' + token1 + '&asset2_amount=' + amount)
                .then(function (response) {
                    console.log(response);
                    const val2 = response.data.Asset2_price_perAsset1.toFixed(2);
                    setAlgo(val2);
                    //console.log(val2);
                })
        }

        const exchange_for_usdc_to_algo = () => {
            const token2 = 0;
            const token1 = 10458941;
            const amount = 1;
            axios.get(`${API_URL}swap/poolInfo?address=` + algoAdd + '&asset1=' + token2 + '&asset2=' + token1 + '&asset2_amount=' + amount)
                .then(function (response) {
                    console.log(response);
                    const val2 = response.data.Asset2_price_perAsset1.toFixed(2);
                    setUsdc(val2);
                    //console.log(val2);
                })
        }

        const exchange_for_algo_to_pago = () => {
            const token2 = 108232239;
            const token1 = 0;
            const amount = 3;
            axios.get(`${API_URL}swap/poolInfo?address=` + algoAdd + '&asset1=' + token2 + '&asset2=' + token1 + '&asset2_amount=' + amount)
                .then(function (response) {
                    console.log(response);
                    const val2 = response.data.Asset2_price_perAsset1 / 3;
                    //setAlgo(val2);
                    setAlgoToPago(val2.toFixed(2));
                })
        }

        const exchange_for_pago_to_algo = () => {
            const token2 = 0;
            const token1 = 108232239;
            const amount = 1;
            axios.get(`${API_URL}swap/poolInfo?address=` + algoAdd + '&asset1=' + token2 + '&asset2=' + token1 + '&asset2_amount=' + amount)
                .then(function (response) {
                    console.log(response);
                    const val2 = response.data.Asset2_price_perAsset1.toFixed(2);
                    setPagoToAlgo(val2);
                    //console.log(val2);
                })
        }

        exchange_for_algo_to_usdc();
        exchange_for_usdc_to_algo();
        exchange_for_algo_to_pago();
        exchange_for_pago_to_algo();

    }, []);
    console.log(AlgoTOPago, PagoTOAlgo);
    return (
        <>
            <Header />

            <Box minH="100vh" pt="50px">
                {/* first component start from here  */}

                <Box w="100%" >
                    <Container maxW={'6xl'} my="80px" py="30px" borderRadius="15px" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px">
                        <TableContainer>
                            <Table variant='simple'>

                                <Thead>
                                    <Tr>
                                        <Th fontSize="15px">Pools</Th>
                                        <Th fontSize="15px">Asset1 Reserve</Th>
                                        <Th fontSize="15px">Asset2 Reserve</Th>
                                        <Th fontSize="15px">Exchange Rate</Th>
                                        <Th fontSize="15px">Actions</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {/* {tokens.map((item) => ( */}
                                    <Tr>
                                        <Td><HStack justifyItems="center" justifyContent="space-between" w="250px">
                                            <HStack>
                                                <Image src={algo} w="25px" h="25px" borderRadius="50%" mr="-15px" />
                                                <Image src={usdc} w="25px" h="25px" borderRadius="50%" />
                                            </HStack>
                                            <HStack>
                                                <HStack>
                                                    <IoIosCheckmarkCircleOutline color='green' />
                                                    <Text fontSize="16px" fontWeight="bold">ALGO</Text>
                                                </HStack>
                                                <Text>/</Text>
                                                <HStack>
                                                    <Text fontSize="16px" fontWeight="bold">USDC</Text>
                                                    <IoIosHelpCircleOutline color='rgb(246,152,30)' />
                                                </HStack>

                                            </HStack>
                                            <HStack width="40px" borderRadius="40px" bgColor="gray.300">
                                                <Text textAlign="center" p="5px">0.3%</Text>
                                            </HStack>
                                        </HStack></Td>
                                        <Td fontSize="16px" fontWeight="bold">{info ? info.asset2_reserves : "--"}</Td>
                                        <Td fontSize="16px" fontWeight="bold">{info ? info.asset1_reserves : "--"}</Td>
                                        <Td fontSize="16px" fontWeight="bold">
                                            <Button onClick={handleExchangeRate} bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}>
                                                {exchange ?
                                                    <HStack>
                                                        <Text>1 USDC = {UsdcTOAlgo} ALGO </Text>
                                                        <IoIosSwap />
                                                    </HStack> :
                                                    <HStack>
                                                        <Text>1 ALGO = {AlgoToUsdc} USDC </Text>
                                                        <IoIosSwap />
                                                    </HStack>
                                                }


                                            </Button>
                                        </Td>
                                        <Td fontSize="25px" fontWeight="bold"><HStack>
                                            <Tooltip label='Add Liquidity' aria-label='A tooltip'>
                                                <span>
                                                    <IoIosAddCircleOutline color='green' cursor="pointer" onClick={handleLiquidityRow1} />
                                                </span>

                                            </Tooltip>
                                            <Tooltip label='Swap' aria-label='A tooltip'>
                                                <Link to="/swap">
                                                    <span>
                                                        <IoIosSync color='rgb(246,152,30)' cursor="pointer" />
                                                    </span>
                                                </Link>
                                            </Tooltip>
                                            <Tooltip label='Withdraw Liquidity' aria-label='A tooltip'>

                                                <span><IoIosRemoveCircleOutline color='rgb(252, 36, 3)' cursor="pointer" onClick={handleWithdrawRow1} />
                                                </span>

                                            </Tooltip>

                                        </HStack></Td>
                                    </Tr>
                                    {/* ))} */}

                                    <Tr>
                                        <Td><HStack justifyItems="center" justifyContent="space-between" w="250px">
                                            <HStack>
                                                <Image src={algo} w="25px" h="25px" borderRadius="50%" mr="-15px" />
                                                <Image src={pago} w="25px" h="25px" borderRadius="50%" />
                                            </HStack>
                                            <HStack>
                                                <HStack>
                                                    <IoIosCheckmarkCircleOutline color='green' />
                                                    <Text fontSize="16px" fontWeight="bold">ALGO</Text>
                                                </HStack>
                                                <Text>/</Text>
                                                <HStack>
                                                    <Text fontSize="16px" fontWeight="bold">PAGO</Text>
                                                    <IoIosHelpCircleOutline color='rgb(246,152,30)' />
                                                </HStack>

                                            </HStack>
                                            <HStack width="40px" borderRadius="40px" bgColor="gray.300">
                                                <Text textAlign="center" p="5px">0.3%</Text>
                                            </HStack>
                                        </HStack></Td>
                                        {/* <Td fontSize="16px" fontWeight="bold">{TVL2 * 0.86}</Td> */}
                                        <Td fontSize="16px" fontWeight="bold">{info2 ? info2.asset2_reserves : "--"}</Td>
                                        <Td fontSize="16px" fontWeight="bold">{info2 ? info2.asset1_reserves : "--"}</Td>
                                        <Td fontSize="16px" fontWeight="bold">
                                            <Button onClick={handleExchangeRate2} bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}>
                                                {exchange2 ?
                                                    <HStack>
                                                        <Text>1 PAGO = {PagoTOAlgo} ALGO </Text>
                                                        <IoIosSwap />
                                                    </HStack> :
                                                    <HStack>
                                                        <Text>1 ALGO = {AlgoTOPago} PAGO </Text>
                                                        <IoIosSwap />
                                                    </HStack>
                                                }


                                            </Button>
                                        </Td>
                                        <Td fontSize="25px" fontWeight="bold"><HStack>
                                            <Tooltip label='Add Liquidity' aria-label='A tooltip'>
                                                <span>
                                                    <IoIosAddCircleOutline color='green' cursor="pointer" onClick={handleLiquidityRow2} />
                                                </span>

                                            </Tooltip>
                                            <Tooltip label='Swap' aria-label='A tooltip'>
                                                <Link to="/swap">
                                                    <span>
                                                        <IoIosSync color='rgb(246,152,30)' cursor="pointer" />
                                                    </span>
                                                </Link>
                                            </Tooltip>
                                            <Tooltip label='Withdraw Liquidity' aria-label='A tooltip'>
                                                <span> <IoIosRemoveCircleOutline color='rgb(252, 36, 3)' cursor="pointer" onClick={handleWithdrawRow2} /></span>
                                            </Tooltip>

                                        </HStack></Td>
                                    </Tr>



                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Container>
                </Box>
            </Box>
        </>
    )
}

export default Pools