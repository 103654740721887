import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import "swiper/css/navigation";
import { Box, Button, Container, Flex, Heading, HStack, Image, Stack, Text, useColorModeValue, VStack, Avatar } from '@chakra-ui/react';
import { TrendingAllCategories } from './data/sliderData';
import { Navigation } from "swiper";
import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    query, where,
    update
} from "firebase/firestore";
import { db } from '../firebase/FirebaseConfig';
import { useSelector } from 'react-redux';
import dummyInage from '../images/pago-head.png'
import { useNavigate } from 'react-router-dom';


//console.log(collectionData);


//console.log(myCollection);

function TrendingInAllComponent() {
    const [collectionData, setCollection] = useState([]);
    const nftCollectionRef = collection(db, "collections");
    const textColor = useColorModeValue("gray.600", "whiteAlpha.500");
    const algoAdd = useSelector(state => state.token.myAlgoAddress);
    const color = useColorModeValue('white', 'gray.900')
    const navigate = useNavigate();

    useEffect(() => {

        const collection = async () => {
            const data = await getDocs(nftCollectionRef);
            setCollection(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };

        collection();




    }, []);

    const handleNFT = (data) => {
        console.log(data);
        console.log(data.value);
        navigate("/nft-profile", { state: { item: data } });
    }

    const myCollection = collectionData.length > 0 ? collectionData.filter((data) => data.address === algoAdd) : null;
    return (
        <>
            <Swiper

                breakpoints={{
                    // when window width is >= 640px
                    640: {
                        width: 640,
                        slidesPerView: 1,
                    },
                    // when window width is >= 768px
                    768: {
                        width: 768,
                        slidesPerView: 2,
                    },
                }}
                spaceBetween={30}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}

                className="mySwiper"
            >

                {collectionData ? collectionData.map((item) => (
                    <SwiperSlide key={item.id} style={{ margin: "0px 10px 0px 10px" }}>
                        <Box h="500px" border="1px solid gray" borderRadius="12px" borderColor="gray.200" onClick={() => handleNFT(item)} cursor="pointer" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" my="25px" >
                            <VStack>
                                <Image mb="-30px" borderTopLeftRadius="12px" borderTopRightRadius="12px" h="250px" w="100%" src={item.featureImage} />
                                <Image src={item.logoImage} h="60px" w="60px" borderRadius="50%" objectFit="cover" border="3px solid white" bgColor="gray.400" />
                                <Text fontWeight="bold" fontSize="21px" marginBottom="-40px">{item.collectionName}</Text>
                                <Text fontSize="16px" color={textColor}><span color='gray.100'>by</span> {item.category}</Text>
                                <Text p="20px" color={textColor} textAlign="center">{item.description}</Text>
                            </VStack>
                        </Box>
                    </SwiperSlide>

                ))
                    :
                    <SwiperSlide >
                        <Box h="500px" border="1px solid gray" borderRadius="12px" borderColor="gray.200" >
                            <VStack>
                                <Image mb="-30px" borderTopLeftRadius="12px" borderTopRightRadius="12px" h="250px" w="100%" src={dummyInage} />
                                <Image src={dummyInage} h="40px" w="40px" borderRadius="50%" objectFit="cover" border="3px solid white" bgColor="gray.400" />
                                <Text fontWeight="bold" fontSize="21px" marginBottom="-40px">My Collection</Text>
                                <Text fontSize="16px" color={textColor}><span color='gray.100'>by</span> Exclusive Collection</Text>
                                <Text p="20px" color={textColor} textAlign="center">This is the best collection</Text>
                            </VStack>
                        </Box>
                    </SwiperSlide>}

                {/* {myCollection.map((data, key) => (
                    <SwiperSlide key={data.collectionName}>

                        <Box
                            maxW={'445px'}
                            w={'full'}
                            bg={color}
                            boxShadow={'2xl'}
                            rounded={'md'}
                            p={6}
                            overflow={'hidden'}
                            cursor="pointer"
                            h="300px"
                        >
                            <Box
                                h={'110px'}
                                bg={'gray.100'}
                                mt={-6}
                                mx={-6}
                                mb={6}
                                pos={'relative'}>
                                <Image
                                    src={data.featureImage}
                                    layout={'fill'}
                                />
                            </Box>
                            <Stack>

                                <Text color={'gray.500'}>
                                    {data.description}
                                </Text>
                            </Stack>
                            <Stack mt={6} direction={'row'} spacing={4} align={'center'}>
                                <Avatar
                                    src={data.logoImage}
                                    alt={'Author'}
                                />
                                <Stack direction={'column'} spacing={0} fontSize={'sm'}>
                                    <Text fontWeight={600}>{data.collectionName}</Text>

                                </Stack>
                            </Stack>
                        </Box>

                    </SwiperSlide>

                ))} */}

            </Swiper>
        </>
    );
}

export default TrendingInAllComponent;