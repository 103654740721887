import { Box, Button, Container, HStack, Image, Table, TableCaption, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, VStack } from '@chakra-ui/react';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import DesktopNav from '../components/layout/Navbar/DesktopNav/DesktopNav';
import pagoHead from '../components/images/pago-head.png';
import { db } from '../components/firebase/FirebaseConfig';
import { useState } from 'react';
import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    query, where,
    update
} from "firebase/firestore";
import { Link, useNavigate } from 'react-router-dom';
import Header from '../components/header/Header';
import { ReactComponent as UsdcCoinImg } from '../components/images/Icons/usd-coin-usdc-logo.svg';
import pagoImage from "../components/images/Pago-Coin.png"

function MyAuctions() {
    const algoAdd = useSelector(state => state.token.myAlgoAddress);
    const bgColor = useColorModeValue("rgb(230, 253, 255)", "blackAlpha.200");
    const nftBorrowtRef = collection(db, "borrow");
    const [borrowNFT, setBorrowNFT] = useState([]);
    const [nfts, setNfts] = useState([]);
    const navigate = useNavigate()

    useEffect(() => {
        const getNfts = async () => {

            const q = query(collection(db, "nfts"));
            const data = await getDocs(q);

            const q2 = query(nftBorrowtRef, where("account", "==", algoAdd));
            //query(citiesRef, where("state", "==", "CA"));
            const data2 = await getDocs(q2);

            data2.forEach(async (doc) => {
                // doc.data() is never undefined for query doc snapshots
                let docData = doc.data();


                //   console.log(doc.id, " => ", doc.data());
                await setBorrowNFT({ ...borrowNFT, borrowNFT: docData, nftId: doc.id })




            });

            setNfts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));


        };

        getNfts();


    }, []);
    // let borrow = undefined;
    // let BorrowData = [];
    // if (nfts.length > 0) {
    //     borrow = nfts.filter((data) => data.account === algoAdd && data.repay_remaining_amount > 0);
    //     //console.log(nftAuction);

    //     borrow.forEach(function (add) {
    //         BorrowData.push(add);
    //     })
    // } else {
    //     BorrowData = undefined;
    // }

    let myAuction = undefined;
    //let BorrowData = [];
    if (nfts.length > 0) {
        myAuction = nfts.filter((data) => data.type == "auction" && data.auctionOwner == algoAdd);

    } else {

    }

    const handleViewAuction = (data) => {
        navigate("/auction", { state: { data: data } })
    }

    return (
        <>
            <Header />
            <Box minH="100vh" py="50px">
                <Container maxW={'7xl'} mt="80px">
                    <HStack justifyContent="space-between" justifyItems="center" px="20px" py="40px" width="100%" bgColor={bgColor} my="20px" borderRadius="15px">

                        <Box>
                            <Image src={pagoHead} h="60px" w="60px" objectFit="cover" />
                            <VStack align="left">
                                <Text fontSize="25px" fontWeight="bold" mb="-5px">My Auctions</Text>
                                <Text>Total Number of Loans: {myAuction != undefined ? myAuction.length : 0}</Text>
                            </VStack>
                        </Box>
                        <Box paddingRight="80px">
                            <Link to="/all-auction">
                                <Button bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }}>All Auctions</Button>
                            </Link>
                        </Box>


                    </HStack>
                    <TableContainer>
                        <Table variant='simple'>

                            <Thead >
                                <Tr bgColor={bgColor} >
                                    <Th>Asset</Th>
                                    <Th>Asset Name</Th>
                                    <Th>Asset ID</Th>
                                    <Th>Price</Th>
                                    <Th>Minimum Bid Value</Th>
                                    <Th>Action</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {myAuction != undefined ?
                                    myAuction.map((data, index) => (
                                        <Tr key={data.assetID}>
                                            <Td><Image src={data.url} w="50px" h="50px" objectFit="cover" /></Td>
                                            <Td>
                                                <VStack spacing={2} align="start">
                                                    <Text>{data.name ? data.name : "--"}</Text>

                                                </VStack>
                                            </Td>
                                            {/*<Td>{data.name ? data.name : '--'}</Td>*/}
                                            <Td>
                                                {
                                                    data.assetID
                                                }
                                            </Td>
                                            <Td>{data.price}</Td>
                                            <Td>{(data.minimumBidValue).toFixed(2)}</Td>

                                            <Td>

                                                <HStack>


                                                    <Button bgGradient='linear(to-r, #85BF47, #38B455)' color="whiteAlpha.900" _hover={{ bgGradient: 'linear(to-r, #38B455, #85BF47)' }} onClick={() => handleViewAuction(data)}>View Auction</Button>

                                                </HStack>
                                            </Td>

                                        </Tr>
                                    )) :
                                    <Tr>
                                        <Td>--</Td>
                                        <Td>--</Td>
                                        <Td>--</Td>
                                        <Td>--</Td>
                                    </Tr>
                                }

                            </Tbody>

                        </Table>
                    </TableContainer>
                </Container>
            </Box>
        </>
    )
}

export default MyAuctions
